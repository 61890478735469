import React from 'react';
import gsap from 'gsap'
import { heroBackground } from '../../images'

  const handleScrollTo = event => {
    gsap.to(window, { duration: .75, scrollTo: event.target.dataset.to, ease: 'power2.easeOut' })
  }

const Hero = () => (
  <div className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url("${heroBackground}")`}}>
  <div className="container mx-auto text-white px-6 xl:px-0 pb-32 pt-56">
      <div className="md:w-8/12 lg:w-6/12 xl:w-5/12">
        <h1 className="text-3xl font-bold">Invertir en Real Estate y ganar en dólares</h1>
        <p className="font-light mt-3 mb-6">Mejore la rentabilidad de sus inversiones en dólares. Garantizamos tu inversión en Estados Unidos de manera personalizada y segura.</p>
        <button onClick={ handleScrollTo } data-to="#productos" className="bg-secondary-600 hover:bg-secondary-500 transition-all uppercase py-4 md:py-3 px-6 rounded md:text-xs mt-8 md:mt-6 focus:outline-none cursor-pointer">Descubre cómo</button>
      </div>
  </div>
  </div>
);

export default Hero;
