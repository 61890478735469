import React, { useState } from 'react';
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'

const Form = () => {

  const [ state, setState ] = useState({
    nombre: '',
    apellido: '',
    email: '',
    tel_codigo_pais: '',
    tel_codigo_area: '',
    telefono: '',
    tipo_telefono: 'celular',
    medio_comunicacion: '',
    mensaje: ''
  })

  const [ sent, setSent ] = useState(false)
  const [ sending, setSending ] = useState(false)

  const [ errorNombre, setErrorNombre ] = useState(false)
  const [ errorApellido, setErrorApellido ] = useState(false)
  const [ errorEmail, setErrorEmail ] = useState(false)
  const [ errorTelefonoPais, setErrorTelefonoPais ] = useState(false)
  const [ errorTelefonoArea, setErrorTelefonoArea ] = useState(false)
  const [ errorTelefonoNumero, setErrorTelefonoNumero ] = useState(false)
  const [ errorConsulta, setErrorConsulta ] = useState(false)
  const [ errorMensaje, setErrorMensaje ] = useState(false)
  const [ errorValidate, setErrorValidate ] = useState(false)
  const [ errorServidor, setErrorServidor ] = useState(false)

  const handleOnChange = (event) => {
    setState({
      ...state,
      [event.target.id]: event.target.value
    })
    if(isEmpty(event.target.value)) { setErrorConsulta(true)} else { setErrorConsulta(false); setErrorValidate(true) }
    //setErrorValidate(false)
  }

  const validationAllInputs = () => {
    if(isEmpty(state.apellido)) { setErrorApellido(true)} else { setErrorApellido(false); setErrorValidate(true) }
    if(isEmpty(state.nombre)) { setErrorNombre(true)} else { setErrorNombre(false); setErrorValidate(true) }
    if(!isEmail(state.email) || isEmpty(state.email)) { setErrorEmail(true)} else {setErrorEmail(false); setErrorValidate(true)}
    if(isEmpty(state.tel_codigo_pais)) { setErrorTelefonoPais(true)} else {setErrorTelefonoPais(false); setErrorValidate(true)}
    if(isEmpty(state.tel_codigo_area)) { setErrorTelefonoArea(true)} else {setErrorTelefonoArea(false); setErrorValidate(true)}
    if(isEmpty(state.telefono)) { setErrorTelefonoNumero(true)} else {setErrorTelefonoNumero(false); setErrorValidate(true)}
    if(isEmpty(state.medio_comunicacion)) { setErrorConsulta(true)} else {setErrorConsulta(false); setErrorValidate(true)}
    if(isEmpty(state.mensaje)) { setErrorMensaje(true)} else {setErrorMensaje(false); setErrorValidate(true)}
  }

  const validateInputs = (event) => {
    event.preventDefault()
    if(!errorApellido && !errorNombre && !errorEmail && !errorTelefonoPais && !errorTelefonoArea && !errorTelefonoNumero && !errorConsulta && !errorMensaje && errorValidate) {
      sendForm()
    } else {
      console.log('debe completar todos los campos')
      validationAllInputs()
    }
  }
  
  const handleBlurInputs = (event) => {
    
    switch (event.target.id) {
      case 'apellido':
        if(isEmpty(state.apellido)) { setErrorApellido(true)} else { setErrorApellido(false); setErrorValidate(true) }
        break;
      case 'nombre':
        if(isEmpty(state.nombre)) { setErrorNombre(true)} else { setErrorNombre(false); setErrorValidate(true) }
        break;
      case 'email':
        if(!isEmail(state.email) || isEmpty(state.email)) { setErrorEmail(true)} else {setErrorEmail(false); setErrorValidate(true)}
        break;
      case 'tel_codigo_pais':
        if(isEmpty(state.tel_codigo_pais)) { setErrorTelefonoPais(true)} else {setErrorTelefonoPais(false); setErrorValidate(true)}
        break;
      case 'tel_codigo_area':
        if(isEmpty(state.tel_codigo_area)) { setErrorTelefonoArea(true)} else {setErrorTelefonoArea(false); setErrorValidate(true)}
        break;
      case 'telefono':
        if(isEmpty(state.telefono)) { setErrorTelefonoNumero(true)} else {setErrorTelefonoNumero(false); setErrorValidate(true)}
        break;
      case 'medio_comunicacion':
        if(isEmpty(state.medio_comunicacion)) { setErrorConsulta(true)} else {setErrorConsulta(false); setErrorValidate(true)}
        break;
      case 'mensaje':
        if(isEmpty(state.mensaje)) { setErrorMensaje(true)} else {setErrorMensaje(false); setErrorValidate(true)}
        break;
      default:
        console.log('hello');
        break;
    }
    
  }

  const Message = () => {
    return(
       errorServidor ? <div className="bg-red-200 text-red-500 inline text-sm mb-3 py-3 px-6 rounded-full">Ah ocurrido un error. Itenta más tarde, por favor.</div> : <div className="bg-gray-200 text-gray-500 inline text-sm mb-3 py-3 px-6 rounded-full">*Todos los campos son requeridos</div>
    )
  }

  const sendForm = () => {
    setSending(true)
    console.log('enviando formulario...', state)

    fetch(`${process.env.REACT_APP_API_URL}api/contacto`, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(state), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if(res.status === 202) {
        console.log('mensaje enviado')
        setState({
          nombre: '',
          apellido: '',
          email: '',
          tel_codigo_pais: '',
          tel_codigo_area: '',
          telefono: '',
          tipo_telefono: 'celular',
          medio_comunicacion: '',
          mensaje: ''
        })
        setSent(true)
        setSending(false)
        setErrorServidor(false)
      }
    } )
    .catch( error => { console.log('se ha producido un error en el servidor', error); setErrorServidor(true) })
  }

  return(
    <form className="lg:w-10/12 xl:w-9/12 mx-auto" >

      {/* datos generales */}
      <div className="">
        <h3 className="uppercase text-primary-700 text-lg mb-4">Datos generales</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
          <div className="flex flex-col mb-4">
            <input onBlur={ handleBlurInputs } onChange= { handleOnChange } className="border text-gray-500 py-3 px-2" placeholder='Apellido*' id="apellido" type="text" value={ state.apellido } required />
            { errorApellido && <span className="text-sm pt-2 text-red-300">* Debes completar un apellido correcto</span> }
          </div>
          <div className="flex flex-col mb-4">
            <input  onBlur={ handleBlurInputs } onChange= { handleOnChange } className="border text-gray-500 py-3 px-2 " placeholder='Nombre*' id="nombre" type="text" value={ state.nombre } required />
            { errorNombre && <span className="text-sm pt-2 text-red-300">* Debes completar un nombre correcto</span> }
          </div>
        </div>
        <div className="flex flex-col pb-6">
          <input onBlur={ handleBlurInputs } onChange= { handleOnChange } className="border text-gray-500 py-3 px-2 w-full" placeholder='E-mail*' id="email" type="email" pattern='[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}' value={ state.email } required />
          { errorEmail && <span className="text-sm pt-2 text-red-300">* Debes completar un email válido</span> }
        </div>
      </div>

      {/* telefono */}
      <h4 className="text-primary-700 md:text-sm mb-4"><span className="uppercase">Teléfono</span> (sin 0 ni 15)</h4>
      <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4">
        <div className="mb-6 flex flex-col">
          <input onBlur={ handleBlurInputs } onChange= { handleOnChange } className="border text-gray-500 py-3 px-2" placeholder='54 (ARG)*' type="text" value={ state.tel_codigo_pais } id="tel_codigo_pais" required />
          { errorTelefonoPais && <span className="text-sm pt-2 text-red-300">* Completar campo</span> }
        </div>
        <div className="mb-6 flex flex-col">
          <input onBlur={ handleBlurInputs } onChange= { handleOnChange } className="border text-gray-500 py-3 px-2" placeholder='Cod. área (Ej.: 381)*' type="text" value={ state.tel_codigo_area } id="tel_codigo_area" required />
          { errorTelefonoArea && <span className="text-sm pt-2 text-red-300">* Completar campo</span> }
        </div>
        <div className="mb-6 col-span-3">
          <input onBlur={ handleBlurInputs } onChange= { handleOnChange } className="border text-gray-500 py-3 px-2 w-full" placeholder='Número (sin 15 si es celular)*' type="text" value={ state.telefono } id="telefono" required />
          { errorTelefonoNumero && <span className="text-sm pt-2 text-red-300">* Completar campo</span> }
        </div>
      </div>

      { /* tipo de telefono */ }
      <div className="pb-6">
        <div className="flex justify-end">
          <div className="px-3">
            <input onClick={ () => setState({ ...state, tipo_telefono: 'fijo' }) } name="tipo_telefono" type="radio" id="fijo" value="fijo" />
            <label className="pl-2" htmlFor="fijo">Fijo</label>
          </div>
          <div className="px-3">
            <input name="tipo_telefono" type="radio" id="celular" value="celular" checked={state.tipo_telefono === 'celular' } />
            <label onClick={ () => setState({...state, tipo_telefono: 'celular' }) } className="pl-2" htmlFor="celular">Celular</label>
          </div>
        </div>
      </div>

      {/* consultas */}
      <div className="mb-4">
        <div className="mb-2">
          <h3 className="uppercase text-primary-700 text-lg pb-2">Medio de comunicación</h3>
          <select onChange= { handleOnChange } className="py-3 px-2 w-full border" name="consulta" id="medio_comunicacion">
            <option value="">Seleccione el tipo de consulta</option>
            <option value="email">Email</option>
            <option value="reunion">Reunión</option>
            <option value="llamada">Llamada telefónica</option>
            <option value="videollamada">Videollamada</option>
          </select>
        </div>
        { errorConsulta && <span className="text-sm pt-2 text-red-300">* Debes seleccionar una consulta</span> }
      </div>
  
      {/* mensaje */}
      <div className="mb-8">
        <h3 className="uppercase text-primary-700 text-lg mb-4">Mensaje</h3>
        <textarea onBlur={ handleBlurInputs } onChange= { handleOnChange } id="mensaje" className="py-3 px-2 w-full border" value={ state.mensaje } required ></textarea>
        { errorMensaje && <span className="text-sm pt-2 text-red-300">* Debes escribir un mensaje</span> }
      </div>

      { sent ? <div className="bg-green-100 text-green-700 font-semibold inline text-sm mb-3 py-3 px-6 rounded-full">El mensaje fue enviado correctamente. Gracias!</div> : <Message /> }
      <div className="flex justify-end mt-6">
        {
          sending ?
            <button onClick={ validateInputs } className="bg-primary-800 text-white w-full md:w-3/12 py-3 rounded" ><i className="fas fa-circle-notch px-3 animate-spin"></i>Enviando</button> :
            <button onClick={ validateInputs } className="bg-primary-800 text-white w-full md:w-3/12 py-3 rounded" >Enviar</button>
        }
      </div>
    </form>
  );
}

export default Form;
