import React, { useState } from 'react';
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { investmentLogo } from '../../images'

gsap.registerPlugin(ScrollToPlugin)

const Header = () => {

  const [ show, setShow ] = useState(false)

  const handleScrollTo = event => {
    let scrollValue = ''
    event.target.dataset.to === '#inicio' ? scrollValue = 0 : scrollValue = event.target.dataset.to
    setShow(false) 
    gsap.to(window, { duration: .75, scrollTo: scrollValue, ease: 'power2.easeOut' })
  }

  return(
    <header className="bg-white shadow-lg fixed w-full z-10 " >
      {/* NAV MOBILE */}
      {
        show && <div className="absolute z-20 w-screen h-screen bg-black opacity-75 flex justify-center items-center">
          <div onClick={ () => setShow(false) } className="static">
            <i className="text-white fas fa-times fa-3x absolute m-8 top-0 right-0"></i>
          </div>
          <ul className="flex flex-col justify-around uppercase text-center text-4xl">
            <button onClick={ handleScrollTo } data-to="#somos" className="text-white hover:text-secondary-300 transition-all py-10 cursor-pointer uppercase" >Quienes somos</button>
            <button onClick={ handleScrollTo } data-to="#metodologia" className="text-white hover:text-secondary-300 transition-all py-10 cursor-pointer uppercase" >Metodología</button>
            <button onClick={ handleScrollTo } data-to="#productos" className="text-white hover:text-secondary-300 transition-all py-10 cursor-pointer uppercase" >Productos</button>
            <button onClick={ handleScrollTo } data-to="#expertos" className="text-white hover:text-secondary-300 transition-all py-10 cursor-pointer uppercase" >Expertos</button>
            <button onClick={ handleScrollTo } data-to="#contactos" className="text-white hover:text-secondary-300 transition-all py-10 cursor-pointer uppercase" >Contactos</button>
          </ul>
        </div>
      }

      <div className="container mx-auto px-6 xl:px-0 py-6 md:py-3 flex justify-between items-center md:items-end ">
        <button id="inicio"><img className="cursor-pointer " onClick={ handleScrollTo } data-to="#inicio" src={ investmentLogo } alt="Despertar Investment Logo"/></button>
        <nav>
          <ul className="md:flex uppercase text-xs hidden">
            <li><button onClick={ handleScrollTo } data-to="#somos" className="hover:border-b-2 hover:border-secondary-300 text-gray-600 hover:text-secondary-300 transition-all px-3 py-2 cursor-pointer uppercase" >Quienes somos</button></li>
            <li><button onClick={ handleScrollTo } data-to="#metodologia"  className="hover:border-b-2 hover:border-secondary-300 text-gray-600 hover:text-secondary-300 transition-all px-3 py-2 cursor-pointer uppercase" >Metodología</button></li>
            <li><button onClick={ handleScrollTo } data-to="#productos"  className="hover:border-b-2 hover:border-secondary-300 text-gray-600 hover:text-secondary-300 transition-all px-3 py-2 cursor-pointer uppercase" >Productos</button></li>
            <li><button onClick={ handleScrollTo } data-to="#expertos"  className="hover:border-b-2 hover:border-secondary-300 text-gray-600 hover:text-secondary-300 transition-all px-3 py-2 cursor-pointer uppercase" >Expertos</button></li>
            <li><button onClick={ handleScrollTo } data-to="#contactos"  className="hover:border-b-2 hover:border-secondary-300 text-gray-600 hover:text-secondary-300 transition-all px-3 py-2 cursor-pointer uppercase" >Contactos</button></li>
          </ul>
          <p onClick={ () => setShow(true)} className="md:hidden p-6" >
            <i className="fas fa-bars"></i>
          </p>
        </nav>
      </div>
    </header>
  )
}

export default Header;
