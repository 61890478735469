import React, { useState, useEffect, useRef } from 'react'
import gsap from 'gsap'
import { Header, Hero, Form, Footer } from '../../components'
import { pizarraGraphic, scrumGraphic, misionVision, compraVentaProducto, adminitracionProducto, desarrolloProducto, negociosProducto, avatarRodi, avatarLeon, heroContacts } from '../../images'

//const lorem = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit itaque fuga aperiam distinctio, perferendis harum eveniet vel ex laborum earum cum debitis expedita dolorum optio minus sunt, quia ratione vitae.'
const somos = 'Despertar Investment nace de un grupo de empresarios con experiencia en inversiones inmobiliarias. Parte de un patrimonio en Dólares con los cuales hace negocios dentro del Estado de la Florida y así aprovechar la coyuntura del negocio inmobiliario local.'
const metologia = 'Al analizar la dinámica de cada mercado, se puede colocar cada uno dentro del ciclo inmobiliario. Eligiendo el tipo de propiedad y el tiempo. Comprando por debajo del valor del mercado. Pasar de una inversión pasiva a una activa.'
const mision = 'Ser uno de los PRIVATE EQUITY FUND OF REAL ESTATE de mayor influencia en Latinoamérica y el mundo, teniendo como estrategia principal, la sólida relación entre confianza y profesionalismo.'
const vision = 'Consolidarnos como EMPRESA LÍDER en América Latina y el Mundo, siendo el proveedor predilecto de REAL ESTATE manteniendo un excelente nivel de calidad en los servicios, como así también desarrollando y promoviendo mercados más eficientes.'
const productos = 'Es muy importante saber donde estamos a la hora de tomar las decisiones de inversión para poder identificar cuando entrar y salir. Esta es realmente la clave del negocio, el tiempo.'
const leonDescripcionBreve = `<p class="mb-3 text-justify">Nacido en la República Argentina el 06 de Febrero de 1960, contador público de profesión. Su principal actividad ha sido el ejercicio de la profesión, desde una visión empresaria...</p>`
const leonDescripcion = `<p class="mb-3 text-justify">Nacido en la República Argentina el 06 de Febrero de 1960, contador público de profesión. Su principal actividad ha sido el ejercicio de la profesión, desde una visión empresaria. Así ha fundado un Estudio Contable y una Consultora en Organización de Empresas, la que opera en el mercado profesional nacional desde el año 1983, con oficinas principalmente en la ciudad de San Miguel de Tucumán y la Ciudad Autónoma de Buenos Aires. Representante de una organización profesional Internacional.</p>
<p class="mb-3 text-justify">Desde sus orígenes ha complementado su actividad profesional con la actividad académica. Docente por más de 35 años en la Facultad de Ciencias Económicas, de la Universidad Nacional de Tucumán y en los últimos años en un Curso de Posgrado de Alta Gestión de Calidad en Servicios de Salud, Facultad de Medicina de la Universidad Católica Argentina (UCA).</p>
<p class="mb-3 text-justify">Entre sus antecedentes se destacan el haber rediseñado e implementado cambios en numerosas organizaciones públicas y privadas, desde comerciales, industriales y de servicios. También se destaca el haber dirigido el destino de un establecimiento educativo integral, ser síndico y director de una entidad financiera, revisor de cuentas de una asociación civil reconocida en el ámbito del futbol, gerente general de una Federación empresarial, síndico en concursos y quiebras. En el ámbito público ha tenido a cargo importantes procesos de transformación en áreas de salud, llegando a poner en funcionamiento dos hospitales públicos, reorganizar e informatizar el primer nivel de salud (red de APS) y su integración con el segundo y tercer nivel. También en el reordenamiento de municipalidades, abordándolas de manera integral y/o por áreas funcionales, llegando a participar en el diseño de Planes Estratégicos Institucionales.</p>
<p class="mb-3 text-justify">Actualmente es Director de Calidad de Tensolite S.A.. Su principal responsabilidad, más allá de la propia de dirección, es compartir el liderazgo de un cambio en la cultura organizacional de la empresa, facilitando la reconversión a una empresa industrial inteligente.</p>
<p class="mb-3 text-justify">Su experiencia en la profesión y en la consultoría empresarial, y especialmente en entidades financieras, sea como auditor, síndico y de dirección, le permiten estar preparado para el negocio que Despertar Investment propone a sus inversores y accionistas.</p>` 
const rodiDescripcionBreve = `<p class="mb-3 text-justify">Empresario focalizado en transformación Smart de empresas y equipos. Con muchos años de experiencia en gestión de equipos y proyectos en la empresa  Tensolite SA...</p>`
const rodiDescripcion = `<p class="mb-3 text-justify">Empresario focalizado en transformación Smart de empresas y equipos. Con muchos años de experiencia en gestión de equipos y proyectos en la empresa  Tensolite SA.</p>
<p class="mb-3 text-justify">Nacido en 1986 en Tucumán, Argentina. Pertenezco a la quinta  generación de la familia Di Bacco, de origen italiano, con valores que hasta hoy marcan el rumbo y la visión de nuestros negocios.</p>
<p class="mb-3 text-justify">Honestidad, responsabilidad, proactividad, flexibilidad, orientación a los resultados, visión para los negocios y pasión por la innovación son algunos de los valores familiares que trascienden y se intentan potenciar con cada generación. </p>
<p class="mb-3 text-justify">Actualmente soy CEO de Tensolite, una empresa líder con más de 40 años en el mercado de la construcción y la industria de  pretensados de hormigón, fundada por mi abuelo Eduardo Di Bacco. La cual toma envergadura en manos de sus hijos, Rodolfo, Eduardo y Rossana. </p>
<p class="mb-3 text-justify">Este cargo y el mentoreo constante de mi padre, me permitió perfeccionarme en el mundo de los negocios, la industria y el Real Estate. </p>
<p class="mb-3 text-justify">Soy socio fundador de Sitenso. Centro de innovación estratégica y desarrollo. Que surgió de Tensolite y cobró independencia como una unidad de negocio en crecimiento.</p>
<p class="mb-3 text-justify">Como CEO de Despertar Investment, corporación fundada como, resultado años de investigación asesoramiento, y mi experiencia personal. Estoy convencido que es vertebral entender que el mundo hoy nos  exige diversificar e innovar en las inversiones y proyectos que encaramos dando una mirada mas global en nuestro accionar  como empresarios.</p>
<p class="mb-3 text-justify">Con Despertar mediante las  inversiones en bienes raíces buscamos que nuestros socios vean que diversificar riesgo, generar riqueza y flujo de efectivo es algo real, y transparente con nuestro modelo de este negocio.</p>`

const experts = {
  rodolfo: {
    name: 'Rodolfo Di Bacco',
    position: 'CEO',
    content: rodiDescripcion, 
    srcUrl: avatarRodi,
    redes:`<a target="blank" href="https://www.facebook.com/rodolfojdbtensolite"><i class="fab fa-facebook-square p-2 text-primary-700"></i></a>
          <a target="blank" href="https://www.instagram.com/dibaccorj/"><i class="fab fa-instagram-square p-2 text-primary-700"></i></a>
          <a target="blank" href="https://www.linkedin.com/in/rodolfo-jos%C3%A9-di-bacco-80842917a/"><i class="fab fa-linkedin p-2 text-primary-700"></i></a>`
  },
  leon: {
    name: 'Alejandro León',
    position: 'Director',
    content: leonDescripcion, 
    srcUrl: avatarLeon,
    redes:`<a target="blank" href="https://www.facebook.com/profile.php?id=1361455735"><i class="fab fa-facebook-square p-2 text-primary-700"></i></a>
          <a href="https://www.linkedin.com/in/alejandro-le%C3%B3n-8a778155/"><i class="fab fa-linkedin p-2 text-primary-700"></i></a>
          <a href="mailto:alejandroleon.tensolite@gmail.com"><i class="fas fa-envelope p-2 text-primary-700"></i></a>`
  }
}

const ItemGraphic = ({ title, content, image, alt }) => {
  return(
    <>
      <div className="md:w-6/12 pt-8 md:pt-0">
        <h1 className="font-bold text-3xl mb-3 text-primary-900">{ title }</h1>
        <p className="text-gray-600 text-lg">{ content }</p>
      </div>
      <div className="md:w-6/12 px-6 xl:px-0 flex justify-center">
        <img className="" src={ image } alt={ alt }/>
      </div>
    </>
  )
}

const ItemMision = ({ title, content, css='', icon }) => {

  return(
    <>
      <div className={ `bg-blue-500 w-full lg:w-10/12 xl:w-8/12 px-10 py-6 text-white flex flex-col lg:flex-row ${css}` }>
        <i className={ `${ icon } fa-3x pb-2` }></i>
        <div className="pl-0 lg:pl-6">
          <h2 className="text-3xl">{ title }</h2>
          <p className="font-light">{ content }</p>
        </div>
      </div>
    </>
  )
}

const Card = ({ title, src }) => {
  return(
    <div className="bg-white hover:shadow-lg rounded-lg overflow-hidden border">
      <div className="h-40 overflow-hidden">
        <img className="w-full" src={ src } alt="compra y venta"/>
      </div>
      <div className="p-6 text-xl font-bold ">
        <h3 className="text-center text-primary-900">{ title }</h3>
      </div>
    </div>
  )
}

const ExpertsModal = ({ expert, isClosedModal }) => {

  const modalRef = useRef(null)
  
  const cerrarModal = () => {
    isClosedModal(false)
  }

useEffect(()=> {
  const elementModal = modalRef.current,
        tl = gsap.timeline()

  tl.from(elementModal, { duration: 1, opacity: 0, ease: 'power3.easeInOut' })

  return () => {
    //gsap.to(elementModal, { duration: 1, autoAlpha: 0, y: 300, ease: 'power3.easeInOut'})
    console.log('finish')
    tl.reverse()
  }
}, [modalRef])

  return(
    <div ref={ modalRef } className="fixed opacity-100 px-2 xl:px-64 z-30 flex justify-center items-start xl:items-center w-screen h-screen bg-transparent overflow-y-auto">
      <div onClick={ cerrarModal } className="bg-black h-screen w-screen fixed z-40 opacity-60" ></div>
      <div className="bg-gray-100 shadow-xl relative container mx-auto z-50 py-6 xl:py-12 px-6 xl:px-20 flex flex-col xl:flex-row overflow-y-auto">
        <p onClick={ cerrarModal } className="absolute top-0 right-0 p-8 hover:text-red-500 transition cursorpointer" ><i className="fas fa-times fa-2x"></i></p>
        <div className="w-full xl:w-3/12" ><img className="mx-auto" src={ expert.srcUrl } alt={ expert.name } /></div>
        <div className="w-full xl:w-9/12 xl:pl-10 pt-6 xl:pt-0 ">
          <h1 className="font-bold text-center xl:text-left text-4xl xl:text-2xl" >{ expert.name }</h1>
          <h3 className="text-primary-500 text-center xl:text-left text-2xl xl:text-xl">{ expert.position }</h3>
          <p className="pb-10 flex justify-center xl:justify-start text-2xl xl:text-md" dangerouslySetInnerHTML={{ __html: expert.redes }} ></p>
          <p dangerouslySetInnerHTML={{ __html: expert.content }} className="font-light col-count text-justify "></p>
        </div>
      </div>
    </div>
  )
}

const Home = () => {

  const [ showModal, setShowModal ] = useState(false)

  const [ expertContent, setExpertContent ] = useState({})

  const handleShowModal = (event) => {
    event.preventDefault()
    setExpertContent(experts[event.target.dataset.expert])
    setShowModal(true)

  }

  const closeModal = (isClose) => {
    setShowModal(isClose)
  }

  return(
  <>
    { showModal && <ExpertsModal expert={ expertContent } isClosedModal={ closeModal } /> }
    <Header />
    <Hero />
    <section className="md:w-10/12 xl:w-8/12 2xl:w-7/12 mx-auto">
      { /* QUIENES SOMOS */ }
      <section id="somos" className="container mx-auto pt-32 mt-20 md:pt-20 flex flex-col-reverse md:flex-row px-6 md:px-0">
        <ItemGraphic title='Quienes somos' content={ somos } image={ pizarraGraphic } alt='Gráfico Pizarra' />
      </section>
      { /* metedologia */ }
      <section id="metodologia" className="container mx-auto mt-16 pt-32 md:pt-12 flex flex-col-reverse md:flex-row-reverse px-6 md:px-0 items-end">
        <ItemGraphic title='Metodología' content={ metologia } image={ scrumGraphic } alt='Gráfico Scrum' />
      </section>
      { /* mision y vision */ }
      <section className="container mx-auto mt-40 px-6 lg:px-0 bg-right bg-no-repeat py-12 flex flex-col justify-center" style={{ backgroundImage:`url("${misionVision}")` }} >
        { /* mision */ }
        <ItemMision title='Mision' content={ mision } icon='far fa-chart-bar' />
        { /* vision */ }
        <ItemMision title='Vision' content={ vision } icon='fas fa-chart-line' css='mt-16' />
      </section>
      { /* PRODUCTOS */ }
      <section id="productos" className="container mx-auto mt-12 pt-32 md:mt-20 md:pt-20 px-8 md:px-0">
        <div className="md:w-7/12">
          <h1 className="font-bold text-3xl mb-3 text-primary-900">Productos</h1>
          <p className="text-gray-600 text-lg">{ productos }</p>
        </div>
        { /* Cards */ }
        <div className="mx-auto my-12 w-full ">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
            <Card title='Compra y venta de inmuebles' src={ compraVentaProducto } />
            <Card title='Administración de inmuebles' src={ adminitracionProducto } />
            <Card title='Desarrollos de proyectos inmobiliarios' src={ desarrolloProducto } />
            <Card title='Negocios inmobiliarios estructurados' src={ negociosProducto } />
          </div>
        </div>
      </section>
      {/* EXPERTOS */}
      <section id="expertos" className="mt-20 pt-32 md:pt-20 px-8 md:px-0 mb-32">
        <h1 className="font-bold text-3xl mb-3 text-primary-900">Los expertos</h1>

        {/* AVATAR CARDS */}
        <div className="flex flex-col">
          {/* avatar UNO */}
          <div className="relative w-full xl:w-9/12">
            <div className="flex flex-col-reverse md:flex-row items-center md:items-start bg-primary-100">
              {/* description */}
              <div className="p-8 md:w-7/12 ">
                <h3 className="font-bold text-2xl md:text-xl text-primary-700">Rodolfo J. Di Bacco</h3>
                <div className="pt-2 text-primary-600" dangerouslySetInnerHTML={{ __html: rodiDescripcionBreve }} ></div>
                <div className="flex flex-row items-center justify-between" >
                  <div dangerouslySetInnerHTML={{ __html: experts.rodolfo.redes }}></div>
                  <button data-expert="rodolfo" className="hover:text-white text-primary-600 cursor-pointer" onClick={ handleShowModal } >ver más...</button>
                </div>
              </div>
              {/* avatar */}
              <div className="w-full md:w-4/12 md:absolute md:right-0 md:m-6">
                <img className="w-full" src={ avatarRodi } alt="Rodolfo J. Di Bacco"/>
              </div>
            </div>
          </div>


          {/* avatar DOS */}
          <div className="relative w-full xl:w-9/12 mt-24 md:mt-48 lg:mt-56 xl:mt-80 2xl:mt-72 md:self-end">
            <div className="flex flex-col-reverse md:flex-row-reverse items-center md:items-end bg-primary-100">
              {/* description */}
              <div className="p-8 md:w-7/12 ">
                <h3 className="font-bold text-2xl md:text-xl text-primary-700">Alejandro León</h3>
                <div className="pt-2 text-primary-600" dangerouslySetInnerHTML={{ __html: leonDescripcionBreve }} ></div>
                <div className="flex flex-row items-center justify-between" >
                  <div className="" dangerouslySetInnerHTML={{ __html: experts.leon.redes }}></div>
                  <button data-expert="leon" className="hover:text-white text-primary-600 cursor-pointer" onClick={ handleShowModal } >ver más...</button>
                </div>
              </div>
              {/* avatar */}
              <div className="w-full md:w-4/12 md:absolute md:left-0 md:m-6">
                <img className="w-full" src={ avatarLeon } alt="Rodolfo J. Di Bacco"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>


    {/* CONTACTOS */}
    <section className="">
      <img className="w-full" src={ heroContacts } alt="background images"/>
      {/* formulario */}
      <section id="contactos" className="relative -mt-40 pt-16 px-8 md:px-0 mb-32">
        <div className="mx-auto md:w-10/12 xl:w-8/12 2xl:w-7/12 bg-white rounded shadow px-12 py-24 border">
          <h1 className="font-bold text-3xl mb-8 text-center text-primary-900">Contáctanos</h1>
          <Form />
        </div>
      </section>
    </section>
    <Footer />

  </>
  )
}

export default Home;
