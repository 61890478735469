import React from 'react';

const Footer = () => (
  <footer className="bg-primary-800 px-8 md:px-0">
    {/*
    <div className="w-8/12 md:w-3/12 mx-auto flex justify-between text-center pt-16">
      <a href="/"><i className="fab fa-facebook-square text-3xl p-2 text-white"></i></a>
      <a href="/"><i className="fab fa-instagram-square text-3xl p-2 text-white"></i></a>
      <a href="/"><i className="fab fa-linkedin text-3xl p-2 text-white"></i></a>
    </div>
    */}
    <div className="container mx-auto flex flex-col md:flex-row justify-between md:justify-center py-20">
      <div className="flex flex-col text-white text-center md:text-left font-light px-10">
        <h3 className="font-bold text-lg text-center">Oficina</h3>
        <p className=" text-center">Ruta Nac. 9 km 1298</p>
        <p className=" text-center">4101 Los Pocitos, Tucumán</p>
      </div>

      <div className="flex flex-col text-white text-center md:text-left font-light px-10">
        <h3 className="font-bold text-lg text-center">Teléfonos</h3>
        <p className=" text-center">+54 381 437 2215</p>
      </div>
      {/*
      <div className="flex flex-col text-white text-center md:text-left font-light py-10">
        <h3 className="font-bold text-lg">Correo Electónico</h3>
        <p>despertar@investment.com</p>
      </div>
      */}
    </div>
  </footer>
);

export default Footer;
